import React from 'react'
import TextField from '@material-ui/core/TextField';

function GetAQuoteFormFields() {
    return(
        <React.Fragment>
            <TextField id='fullName' label='Full Name' margin='normal' fullWidth required type='text'/>
            <TextField id='email' label='Email' fullWidth margin='normal' required type='email'/>
            <TextField id='phoneNumber' label='Phone Number' fullWidth margin='normal' required type='tel'/>
            <TextField id='subject' label='Subject' fullWidth margin='normal' type='text'/>
            <TextField id='details' label='How can we help you?' fullWidth multiline rowsMax='4' margin='normal' required type='text'/>
        </React.Fragment>
    )
}

export default GetAQuoteFormFields;