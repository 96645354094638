import React from 'react'
import companyLogo from '../img/company-logo.png'
import CompanyInformation from './text/CompanyInformation'

function CompanyDescription() {
    return(
        <div className="jumbotron" 
        style={{marginTop: "10px", 
                width: "100%", 
                textAlign: "center", 
                marginLeft: "auto", 
                marginRight: "auto"
        }}>
            <div className="container">
                <img className="d-inline w-100" src={companyLogo} alt="company-logo"/>
                <h1 className="display-5"> <CompanyInformation companyName/> </h1>
                <div className="lead">
                    < CompanyInformation companyDescription />
                </div>
            </div>
        </div>
    )
}

export default CompanyDescription;