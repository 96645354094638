import React from 'react'
import { NavLink } from "react-router-dom";

function NavigationBar() {
    return(
        <nav className="navbar bg-dark fixed-top navbar-expand-lg navbar-dark bg-light">
            <NavLink exact to="/" className="navbar-brand">Premium HI</NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse flex-grow-1 text-right" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <NavLink to="/interior-painting" className="nav-link">Interior Painting</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/exterior-painting" className="nav-link">Exterior Painting</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/carpentry" className="nav-link">Carpentry</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/decks" className="nav-link">Decks</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/flooring" className="nav-link">Flooring</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default NavigationBar;