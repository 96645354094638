import React from 'react'

function ServiceFlooringDescription(props) {
    if(props.short) {
        return(
            <React.Fragment>
                <h5>Flooring</h5>
                <p>
                    When it comes to flooring our company provides you with the following
                    services...
                </p>
            </React.Fragment>
        )
    }

    return(
        <div>
            <h5>Flooring</h5>
            <p>
                When it comes to flooring our company provides you with the following
                services:
            </p>
            <ul style={{marginLeft: "30px"}}>
                <li>Hardwood floor installation</li>
                <li>Hardwood floor refinishing</li>
                <li>Laminate and Pergo floor installation</li>
                <li>Vinyl plank installation</li>
                <li>Carpet installation</li>
                <li>Tile floor installation</li>
            </ul>
        </div>       
    )
}

export default ServiceFlooringDescription;