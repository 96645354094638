import image1 from '../img/servicesGallery/flooring/1.jpg'
import image2 from '../img/servicesGallery/flooring/2.jpg'
import image3 from '../img/servicesGallery/flooring/3.jpg'
import image4 from '../img/servicesGallery/flooring/4.jpg'
import image5 from '../img/servicesGallery/flooring/5.jpg'
import image6 from '../img/servicesGallery/flooring/6.jpg'
import image7 from '../img/servicesGallery/flooring/7.jpg'
import image8 from '../img/servicesGallery/flooring/8.jpg'
import image9 from '../img/servicesGallery/flooring/9.jpg'
import image10 from '../img/servicesGallery/flooring/10.jpg'
import image11 from '../img/servicesGallery/flooring/11.jpg'
import image12 from '../img/servicesGallery/flooring/12.jpg'
import image13 from '../img/servicesGallery/flooring/13.jpg'
import image14 from '../img/servicesGallery/flooring/14.jpg'
import image15 from '../img/servicesGallery/flooring/15.jpg'
import image16 from '../img/servicesGallery/flooring/16.jpg'
import image17 from '../img/servicesGallery/flooring/17.jpg'

export const FlooringGallery =
        [
            {
              src: image1,
              width: 5,
              height: 3
            },
            {
              src: image2,
              width: 5,
              height: 3
            },
            {
              src: image3,
              width: 5,
              height: 3
            },
            {
              src: image4,
              width: 5,
              height: 3
            },
            {
              src: image5,
              width: 5,
              height: 3
            },
            {
              src: image6,
              width: 4,
              height: 7
            },
            {
              src: image7,
              width: 4,
              height: 7
            },
            {
              src: image8,
              width: 5,
              height: 3
            },
            {
              src: image9,
              width: 5,
              height: 3
            },
            {
              src: image10,
              width: 4,
              height: 7
            },
            {
              src: image11,
              width: 5,
              height: 3
            },
            {
              src: image12,
              width: 5,
              height: 3
            },
            {
              src: image13,
              width: 5,
              height: 3
            },
            {
              src: image14,
              width: 5,
              height: 3
            },
            {
              src: image15,
              width: 5,
              height: 3
            },
            {
              src: image16,
              width: 5,
              height: 3
            },
            {
              src: image17,
              width: 4,
              height: 7
            }
        ]