import React from 'react'
import ServiceDetailsPage from '../ServiceDetailsPage';
import {InteriorPaintingGallery} from '../InteriorPaintingGallery';
import ServiceInteriorPaintingDescription from '../text/ServiceInteriorPaintingDescription';
import interiorPaintingImg from '../../img/servicesDetail/interior-painting.jpg';

function InteriorPainting() {
    return(
        <React.Fragment>
            <ServiceDetailsPage
                key            = {interiorPaintingImg}
                imgSrc         = {interiorPaintingImg}
                imgAlt         = {"Interior Painting"}
                content        = {< ServiceInteriorPaintingDescription />}
                photoGalleryId = {'InteriorPaintingPhotoGallery'}
                photos         = {InteriorPaintingGallery}
            />
        </React.Fragment>
    )
}

export default InteriorPainting;