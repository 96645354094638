import React from 'react'
import Slider from '../Slider';
import CompanyDescription from '../CompanyDescription';
import OurServices from '../OurServices';
import WhyChooseUs from '../WhyChooseUs';
import GetAQuotePopup from '../GetAQuotePopup';
import CallUsAlert from '../CallUsAlert';


function Home() {
    return(
        <React.Fragment>
            < CallUsAlert />
            < Slider />
            < CompanyDescription />
            < OurServices />
            < GetAQuotePopup />
            < WhyChooseUs />
        </React.Fragment>        
    )
}

export default Home;
