import React from 'react'

function ServiceDecksDescription(props) {
    if(props.short) {
        return(
            <React.Fragment>
                <h5>Decks</h5>
                <p>
                    Our team of experts will work with you to ensure that your deck
                    is built to last and meet your expectations...
                </p>
            </React.Fragment>
        )
    }

    return(
        <div>
            <h5>Decks</h5>
            <p>
                Our team of experts will work with you to ensure that your deck
                is built to last and meet your expectations.
                <br></br>
                <br></br>
                Our services include:
            </p>
            <ul style={{marginLeft: "30px"}}>
                <li>Deck design</li>
                <li>Deck construction (Composite, wood, vinyl)</li>
                <li>Deck repair</li>
                <li>Deck restoration</li>
                <li>Footing repair or installation</li>
            </ul>
        </div>       
    )
}

export default ServiceDecksDescription;