import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function WhyChooseUsElement(props) {
    return(
        <div className="col" style={{margin: "10px"}}>
            <FontAwesomeIcon icon={props.icon} size="5x"/>
            <p className="card-text">{props.label}</p>
        </div>
    )
}

export default WhyChooseUsElement;