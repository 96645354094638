import React from 'react'
import ServiceDetailsPage from '../ServiceDetailsPage';
import { FlooringGallery } from '../FlooringGallery';
import ServiceFlooringDescription from '../text/ServiceFlooringDescription';
import flooringImg from '../../img/servicesDetail/flooring.jpg'

function Flooring() {
    return(
        <React.Fragment>
            <ServiceDetailsPage 
                key            = {flooringImg}
                imgSrc         = {flooringImg}
                imgAlt         = {"Flooring"}
                content        = {< ServiceFlooringDescription />}
                photoGalleryId = {'FlooringPhotoGallery'}
                photos         = {FlooringGallery}
            />
        </React.Fragment>
    )
}

export default Flooring;