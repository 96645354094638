import React from 'react'
import ServiceDetailsPage from '../ServiceDetailsPage';
import {CarpentryGallery} from '../CarpentryGallery';
import ServiceCarpentryDescription from '../text/ServiceCarpentryDescription';
import carpentryImg from '../../img/servicesDetail/carpentry.jpg'

function Carpentry() {
    return(
        <React.Fragment>
            <ServiceDetailsPage 
                key            = {carpentryImg}
                imgSrc         = {carpentryImg}
                imgAlt         = {"Carpentry"}
                content        = {< ServiceCarpentryDescription />}
                photoGalleryId = {'ExteriorPaintingPhotoGallery'}
                photos         = {CarpentryGallery}
            />
        </React.Fragment>
    )
}

export default Carpentry;