import React from 'react'
import OurServicesElement from './OurServicesElement'
import ServiceInteriorPaintingDescription from './text/ServiceInteriorPaintingDescription'
import ServiceExteriorPaintingDescription from './text/ServiceExteriorPaintingDescription'
import ServiceCarpentryDescription from './text/ServiceCarpentryDescription'
import ServiceDecksDescription from './text/ServiceDecksDescription'
import ServiceFlooringDescription from './text/ServiceFlooringDescription'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import interiorPaintingImg from '../img/servicesSlider/interior-painting.jpg'
import exteriorPaintingImg from '../img/servicesSlider/exterior-painting.jpg'
import carpentryImg from '../img/servicesSlider/carpentry.jpg'
import DecksImg from '../img/servicesSlider/deck.jpg'
import flooringImg from '../img/servicesSlider/flooring.jpg'


function OurServices() {
    const listOfServices = [
        {imgSrc: interiorPaintingImg, serviceDescription: <ServiceInteriorPaintingDescription short/>, buttonLabel: "Read More", servicePath: "/interior-painting", addClassName: "active"},
        {imgSrc: exteriorPaintingImg, serviceDescription: <ServiceExteriorPaintingDescription short/>, buttonLabel: "Read More", servicePath: "/exterior-painting", addClassName: ""},
        {imgSrc: carpentryImg,        serviceDescription: <ServiceCarpentryDescription short/>,        buttonLabel: "Read More", servicePath: "/carpentry",         addClassName: ""},
        {imgSrc: DecksImg,            serviceDescription: <ServiceDecksDescription short/>,            buttonLabel: "Read More", servicePath: "/decks",             addClassName: ""},
        {imgSrc: flooringImg,         serviceDescription: <ServiceFlooringDescription short/>,         buttonLabel: "Read More", servicePath: "/flooring",          addClassName: ""}
    ]

    return(
        <div style={{margin: "auto" }}>
            <hr className="my-4"></hr>

            <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl={false}
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                        max: 1024,
                        min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >

                {listOfServices.map(service => (
                    < OurServicesElement
                        key                = {service.imgSrc}
                        imgSrc             = {service.imgSrc}
                        serviceDescription = {service.serviceDescription}
                        buttonLabel        = {service.buttonLabel}
                        servicePath        = {service.servicePath}
                        addClassName       = {service.addClassName}
                    />
                ))}

            </Carousel>
        </div> 
    )
}

export default OurServices;