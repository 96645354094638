import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CompanyInformation from './text/CompanyInformation'
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import Alerts from './Alerts'

function CallUsAlert() {
    var alertContent = [
        'Call Us! ',
        <FontAwesomeIcon icon={faPhone}/>,
        ' ',
        <CompanyInformation phoneNumberHref/>,
        ' or send us an email ',
        <FontAwesomeIcon icon={faEnvelope}/>,
        ' ',
        <CompanyInformation emailHref/>

    ]
    return(
        <Alerts
            id='CallUsAlert'
            alertType='alert-dark'
            alertContent={alertContent}
        />
    )
}

export default CallUsAlert;