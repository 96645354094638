import image1 from '../img/servicesGallery/carpentry/1.jpg'
import image2 from '../img/servicesGallery/carpentry/2.jpg'
import image3 from '../img/servicesGallery/carpentry/3.jpg'
import image4 from '../img/servicesGallery/carpentry/4.jpg'
import image5 from '../img/servicesGallery/carpentry/5.jpg'
import image6 from '../img/servicesGallery/carpentry/6.jpg'
import image7 from '../img/servicesGallery/carpentry/7.jpg'
import image8 from '../img/servicesGallery/carpentry/8.jpg'
import image9 from '../img/servicesGallery/carpentry/9.jpg'
import image10 from '../img/servicesGallery/carpentry/10.jpg'
import image11 from '../img/servicesGallery/carpentry/11.jpg'
import image12 from '../img/servicesGallery/carpentry/12.jpg'
import image13 from '../img/servicesGallery/carpentry/13.jpg'
import image14 from '../img/servicesGallery/carpentry/14.jpg'
import image15 from '../img/servicesGallery/carpentry/15.jpg'
import image16 from '../img/servicesGallery/carpentry/16.jpg'
import image17 from '../img/servicesGallery/carpentry/17.jpg'
import image18 from '../img/servicesGallery/carpentry/18.jpg'

export const CarpentryGallery =
        [
            {
              src: image1,
              width: 5,
              height: 7
            },
            {
              src: image2,
              width: 5,
              height: 7
            },
            {
              src: image3,
              width: 5,
              height: 8
            },
            {
              src: image4,
              width: 3,
              height: 5
            },
            {
              src: image5,
              width: 3,
              height: 5
            },
            {
              src: image6,
              width: 3,
              height: 5
            },
            {
              src: image7,
              width: 4,
              height: 5
            },
            {
              src: image8,
              width: 5,
              height: 6
            },
            {
              src: image9,
              width: 5,
              height: 6
            },
            {
              src: image10,
              width: 5,
              height: 6
            },
            {
              src: image11,
              width: 6,
              height: 5
            },
            {
              src: image12,
              width: 6,
              height: 3
            },
            {
              src: image13,
              width: 5,
              height: 3
            },
            {
              src: image14,
              width: 3,
              height: 6
            },
            {
              src: image15,
              width: 3,
              height: 6
            },
            {
              src: image16,
              width: 6,
              height: 4
            },
            {
              src: image17,
              width: 5,
              height: 8
            },
            {
              src: image18,
              width: 4,
              height: 3
            }
        ]