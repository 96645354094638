import React from 'react'
import { faAward, faHandshake, faShieldAlt, faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import WhyChooseUsElement from './WhyChooseUsElement';


function WhyChooseUs() {
    const listOfReasons = [
        {icon: faAward,        label: 'Experience'},
        {icon: faHandshake,    label: 'Commitment'},
        {icon: faShieldAlt,    label: 'Reliable'},
        {icon: faBalanceScale, label: 'Honesty'}
    ]

    return(
        <div className="container"style={{margin: "auto", padding: "auto", textAlign: "center"}}>
            <hr className="my-4"></hr>
            <div className="row-6">
                <h1>Why choose Premium Home Improvements?</h1>
            </div>
            <div className="row"></div>
            <div className="row" style={{margin: "20px"}}>
                {listOfReasons.map(reason => (
                    < WhyChooseUsElement
                        key   = {reason.label}
                        icon  = {reason.icon}
                        label = {reason.label}
                    />
                ))}            
            </div>
        </div>
    )
}

export default WhyChooseUs;