import React from 'react'

function GetAQuoteCloseButton(props) {
    return(
        <button 
            type="button" 
            className="close" 
            aria-label="Close"
            onClick={ () => props.closeFunction() }
        >
            <span aria-hidden="true">&times;</span>
        </button>
    )
}

export default GetAQuoteCloseButton;
