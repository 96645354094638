import React from 'react'
import { NavLink } from "react-router-dom";

function OurServicesElement(props) {
    return(
        <div className="card" style={{margin: "2rem"}}>
            <img className="card-img-top" src={props.imgSrc} alt="Card cap"/>
            <div className="card-body">
                <div className="card-text">{props.serviceDescription}</div>
                <NavLink to={props.servicePath} className="btn btn-secondary" style={{marginTop: "6px"}}>{props.buttonLabel}</NavLink>
            </div>
        </div>
    )
}

export default OurServicesElement;