import React from 'react'

function ServiceInteriorPaintingDescription(props) {
    if(props.short) {
        return(
            <React.Fragment>
                <h5>Interior Painting</h5>
                <p>
                    Our experienced and professional painters use the right tools for 
                    the job and top high-quality paints in order to ensure the best 
                    results...
                </p>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <h5> Interior Painting</h5>
            <p>
                    Our experienced and professional painters use the right tools for 
                    the job and top high-quality paints in order to ensure the best 
                    results.
                <br></br>
                <br></br>
                We proudly use <b>Sherwin-Williams</b> and <b>Benjamin Moore</b> paints.
                <br></br>
                <br></br>
                Interior Paint projects include:
            </p>
            <ul style={{marginLeft: "30px"}}>
                <li>Painting</li>
                <li>Walls</li>
                <li>Ceilings</li>
                <li>Baseboards</li>
                <li>Crown moldings</li>
                <li>Doors</li>
                <li>Windows</li>
                <li>Cabinets</li>
                <li>Wallpaper removal</li>
                <li>and more!</li>
            </ul>
        </React.Fragment>
    )
}

export default ServiceInteriorPaintingDescription;