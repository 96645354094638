import React from 'react'
import ServiceDetailsPage from '../ServiceDetailsPage';
import {ExteriorPaintingGallery} from '../ExteriorPaintingGallery';
import ServiceExteriorPaintingDescription from '../text/ServiceExteriorPaintingDescription';
import exteriorPaintingImg from '../../img/servicesDetail/exterior-painting.jpg'

function ExteriorPainting() {
    return(
        <React.Fragment>
            <ServiceDetailsPage 
                key            = {exteriorPaintingImg}
                imgSrc         = {exteriorPaintingImg}
                imgAlt         = {"Exterior Painting"}
                content        = {< ServiceExteriorPaintingDescription />}
                photoGalleryId = {'ExteriorPaintingPhotoGallery'}
                photos         = {ExteriorPaintingGallery}
            />
        </React.Fragment>
    )
}

export default ExteriorPainting;