import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import CompanyInformation from './text/CompanyInformation'

function Footer() {
    return(
        <div style={{background: "#20232a", textAlign: "center", color: "white", margin: "0px", paddign: "0px", height: "100%"}}>
            <div style={{padding: "20px"}}>
                <h5><i><CompanyInformation companyName/></i></h5>
                LIC # <CompanyInformation licNumber/>
            </div>
            
            <div style={{marginTop: "20px"}}>
                <FontAwesomeIcon icon={faPhone}/>  <CompanyInformation phoneNumberHref/>
            </div>
            <div>
                <FontAwesomeIcon icon={faMapMarkerAlt}/>  <CompanyInformation address/>
            </div>
            <div style={{marginBottom: "20px"}}>
                <FontAwesomeIcon icon={faEnvelope}/>  <CompanyInformation emailHref/>
            </div>
            
            <div style={{padding: "20px"}}>
                <CompanyInformation footerCopyright/>
            </div>
        </div>
        
    )
}

export default Footer;