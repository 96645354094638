import image1 from '../img/servicesGallery/interiorPainting/1.jpg'
import image2 from '../img/servicesGallery/interiorPainting/2.jpg'
import image3 from '../img/servicesGallery/interiorPainting/3.jpg'
import image4 from '../img/servicesGallery/interiorPainting/4.jpg'
import image5 from '../img/servicesGallery/interiorPainting/5.jpg'
import image6 from '../img/servicesGallery/interiorPainting/6.jpg'
import image7 from '../img/servicesGallery/interiorPainting/7.jpg'

export const InteriorPaintingGallery =
        [
            {
              src: image1,
              width: 5,
              height: 3
            },
            {
              src: image2,
              width: 5,
              height: 4
            },
            {
              src: image3,
              width: 5,
              height: 3
            },
            {
              src: image4,
              width: 5,
              height: 8
            },
            {
              src: image5,
              width: 5,
              height: 3
            },
            {
              src: image6,
              width: 5,
              height: 3
            },
            {
              src: image7,
              width: 5,
              height: 3
            }
        ]