import React from 'react'

function SubmitButton(props) {
    let buttonLabel  = props.buttonLabel;
    let clickedLabel = props.clickedLabel;
    if(!buttonLabel) {buttonLabel = 'Submit'};
    if(!clickedLabel) {clickedLabel = ' Sending'};

    if(props.clicked) {
        return (
            <button type='submit' className='btn btn-primary' disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {clickedLabel}
            </button>
        )
    }

    return(
        <button ref={props.refFunction} type='submit' className='btn btn-primary'>
            {buttonLabel}
        </button>
    )
}

export default SubmitButton;