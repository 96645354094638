import React from 'react'
import Popup from "reactjs-popup";
import GetAQuoteForm from './GetAQuoteForm';
import CompanyInformation from './text/CompanyInformation';
import GetAQuoteCloseButton from './GetAQuoteCloseButton';

function GetAQuoteButtom() {
    return(    
        <Popup
            trigger={
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4" style={{margin: "2rem"}}>
                            <button type="button" className="btn btn-primary btn-lg btn-block">Get A Quote</button>
                        </div>
                    </div>
                </div>
            }
            
            modal
        >   
            {close => (
                <React.Fragment>
                    <div className="modal-header">
                        <h5 className="modal-title">{<CompanyInformation getAQuoteModalTittle/>}</h5>
                        <GetAQuoteCloseButton id='getAQuoteCloseButton' closeFunction={() => { close(); }}/>
                    </div>
                    <div className="modal-body">
                        <GetAQuoteForm id='getAQuoteForm' closeFunction={() => { close(); }} />
                    </div>
                </React.Fragment>
            )}
        </Popup>
    )
}

export default GetAQuoteButtom;