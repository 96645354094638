import React from 'react'
import GetAQuotePopup from './GetAQuotePopup';
import PhotoGallery from './PhotoGallery';

function ServiceDetailsPage(props) {
    return(
        <div>
            <div style={{textAlign: "center", marginBottom: '20px'}}>
                <img src={props.imgSrc} alt={props.imgAlt} style={{width: "100%", maxHeight: "400px"}}></img>
            </div>
            <div style={{width: "90%", margin: "auto"}}>
                {props.content}
            </div>
            < GetAQuotePopup />
            <PhotoGallery 
                key    = {props.photoGalleryId}
                photos = {props.photos}
            />
            < GetAQuotePopup />
        </div>
    )
}

export default ServiceDetailsPage;