import React from 'react'

function CompanyInformation(props) {
    if(props.companyName) {
        return(
            <React.Fragment>
                Premium Home Improvements
            </React.Fragment>
        )
    }

    if(props.companyDescription) {
        return(
            <React.Fragment>
                Premium Home Improvements LLC is a home remodeling company servicing New Jersey's Union, Morris and Somerset counties.
            </React.Fragment>
        )
    }

    if(props.licNumber) {
        return(
            <React.Fragment>
                13VH10971400
            </React.Fragment>
        )
    }

    if(props.phoneNumber) {
        return(
            <React.Fragment>
                (973) 477-7339
            </React.Fragment>
        )
    }

    if(props.phoneNumberHref) {
        return(
            <a href='tel:+1-973-477-7339'>(973) 477-7339</a>
        )
    }

    if(props.address) {
        return(
            <React.Fragment>
                5 Lyons Mall. Suite 208 Basking Ridge, NJ 07920
            </React.Fragment>
        )
    }

    if(props.email) {
        return(
            <React.Fragment>
                estimates@premiumhic.com
            </React.Fragment>
        )
    }

    if(props.emailHref) {
        return(
            <React.Fragment>
                <a href='mailto:estimates@premiumhic.com'>estimates@premiumhic.com</a>
            </React.Fragment>
        )
    }

    if(props.footerCopyright) {
        return(
            <React.Fragment>
                © All rights reserved Premium Home Improvements
            </React.Fragment>
        )
    }

    if(props.quoteSentError) {
        return(
            <React.Fragment>
                Something went wrong, please try again.
                You can also reach us directly to
                <a href='mailto:estimates@premiumhic.com'> estimates@premiumhic.com</a>
            </React.Fragment>
        )
    }

    if(props.quoteSentOk) {
        return(
            <React.Fragment>
               <b>Thank you for getting in touch!</b><br/>
               We appreciate you contacting us. One of our team members will contact you soon!
            </React.Fragment>
        )
    }

    if(props.getAQuoteModalTittle) {
        return(
            <React.Fragment>
               Get A Quote
            </React.Fragment>
        )
    }
}

export default CompanyInformation;