import React from 'react';
import { Route, HashRouter,  } from "react-router-dom";

import Home from './components/navbarElements/Home';
import InteriorPainting from './components/navbarElements/InteiorPainting';
import ExteriorPainting from './components/navbarElements/ExteriorPainting';
import Carpentry from './components/navbarElements/Carpentry';
import Decks from './components/navbarElements/Decks';
import Flooring from './components/navbarElements/Flooring';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <main className="App-main" style={{marginTop: "60px", maxWidth: "1024px", marginLeft: "auto", marginRight: "auto"}}>
          <HashRouter>
            <ScrollToTop/>
            < NavigationBar />
            <div className="content">
              <Route exact path="/"            component={Home}/>
              <Route path="/interior-painting" component={InteriorPainting}/>
              <Route path="/exterior-painting" component={ExteriorPainting}/>
              <Route path="/carpentry"         component={Carpentry}/>
              <Route path="/decks"             component={Decks}/>
              <Route path="/flooring"          component={Flooring}/>
            </div>
          </HashRouter>
      </main>

      <footer>
        < Footer />
      </footer>
    </div>
  );
}

export default App;
