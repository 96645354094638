import React, { useState  } from 'react'
import axios from 'axios';
import SubmitButton from './GetAQuoteSubmitButton';
import Alerts from './Alerts';
import GetAQuoteFormFields from './GetAQuoteFormFields';
import CompanyInformation from './text/CompanyInformation';


function GetAQuoteForm(props) {
    const [submitClicked, setSubmitClicked] = useState(false);
    const [quoteSentOk, setQuoteSentOk] = useState(false);
    const [quoteSentError, setQuoteSentError] = useState(false);

    function getQuotePostRequest(data) {
        axios.post('/get-a-quote', data)
        .then((response) => {
            getQuotePostRequestOk();
        }).catch((err) => {
            getQuotePostRequestError();
        });
    }

    function getQuotePostRequestOk() {
        setQuoteSentOk(true);
    }

    function getQuotePostRequestError() {
        setSubmitClicked(false);
        setQuoteSentError(true);
    }

    function getEstimatesEmailTemplate(fullName, email, phoneNumber, subject, details) {
        return (            
            '<p>' + 
                '<h2> Hello, <br/>' + 
                'There is a new quote request </h2>' + 
                '<b>Full Name: </b>' + fullName + '<br/>' + 
                '<b>Requester Email: </b>' + email + '<br/>' + 
                '<b>Phone number: </b>' + phoneNumber + '<br/>' + 
                '<b>Subject: </b>' + subject + '<br/>' + 
                '<b>Details: </b>' + details + '<br/>' + 
            '</p>'
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitClicked(true);
        setQuoteSentError(false);
        
        const htmlText = getEstimatesEmailTemplate(
            event.target.fullName.value,
            event.target.email.value,
            event.target.phoneNumber.value,
            event.target.subject.value,
            event.target.details.value
        )
        const data = { subject: 'New Quote Request', html: htmlText }
        getQuotePostRequest(data);
    }

    var alert        = null;
    var actionButton = <SubmitButton id='GetAQuoteSubmitbutton' buttonLabel='Submit'/>;
    var formFields   = <GetAQuoteFormFields/>
    
    if (quoteSentOk) { 
        alert = <Alerts  id='QuoteSentOkAlert' alertType='alert-success' alertContent={<CompanyInformation quoteSentOk/>}/>
        actionButton = null;
        formFields   = null;
    }

    if (quoteSentError) {
        alert = <Alerts  id='QuoteSentErrorAlert' alertType='alert-danger' alertContent={<CompanyInformation quoteSentError/>}/>
    }

    if (submitClicked && !quoteSentOk) {
        actionButton = <SubmitButton id='GetAQuoteSubmitbuttonClicked' clickedLabel=' Sending' clicked/>
    }

    return(
        <React.Fragment>
            { alert }
            <form onSubmit={handleSubmit}>
                {formFields}
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={() => { props.closeFunction() }}> Close </button>
                    { actionButton }
                </div>
            </form>
        </React.Fragment>
    )
}

export default GetAQuoteForm;