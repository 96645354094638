import React from 'react'
import ServiceDetailsPage from '../ServiceDetailsPage';
import { DecksGallery } from '../DecksGallery';
import ServiceDecksDescription from '../text/ServiceDecksDescription';
import DecksImg from '../../img/servicesDetail/deck.jpg'


function Decks() {
    return(
        <React.Fragment>
            <ServiceDetailsPage
                key            = {DecksImg}
                imgSrc         = {DecksImg}
                imgAlt         = {"Decks"}
                content        = {< ServiceDecksDescription />}
                photoGalleryId = {'DecksPhotoGallery'}
                photos         = {DecksGallery}
            />
        </React.Fragment>
    )
}

export default Decks;