import React from 'react'

function Alerts(props) {
    return(
        <div className={'alert ' + props.alertType} role="alert">
            {props.alertContent}
        </div>
    )
}

export default Alerts;