import React from 'react'

function SliderImgWithStyle(props) {
    return(
        <img src={props.imgSrc} alt={props.imgAlt}  style={{
                                                        display: 'block',
                                                        height: 'auto',
                                                        margin: 'auto',
                                                        width: '100%',
                                                    }}
        />
    )
}

export default SliderImgWithStyle;