import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SliderImgWithStyle from './SliderImgWithStyle';

import slide1 from '../img/slider/slide1.jpg'
import slide2 from '../img/slider/slide2.jpg'
import slide3 from '../img/slider/slide3.jpg'
import slide4 from '../img/slider/slide4.jpg'
import slide5 from '../img/slider/slide5.jpg'


function Slider() {
    const sliderImgs = [
        {imgSrc: slide1, imgalt: 'slide1'},
        {imgSrc: slide2, imgalt: 'slide2'},
        {imgSrc: slide3, imgalt: 'slide3'},
        {imgSrc: slide4, imgalt: 'slide4'},
        {imgSrc: slide5, imgalt: 'slide5'},
    ]

    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 1,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 0,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 1,
            partialVisibilityGutter: 0,
        }
    };

    return(
        <React.Fragment>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                autoPlay
                className=""
                containerClass=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {sliderImgs.map(sliderImg => (
                    <SliderImgWithStyle
                        key    = {sliderImg.imgSrc}
                        imgSrc = {sliderImg.imgSrc}
                        imgAlt = {sliderImg.imgAlt}
                    />
                ))}
            </Carousel>
        </React.Fragment>
    )
}

export default Slider;