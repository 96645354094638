import React from 'react'

function ServiceExteriorPaintingDescription(props) {
    if(props.short) {
        return(
            <React.Fragment>
                <h5>Exterior Painting</h5>
                <p>
                    Our experienced and professional painters know what it takes to deliver a
                    high-quality finish that will protect and keep your home looking
                    great for the years to come...
                </p>
            </React.Fragment>
        )
    }

    return(
        <div>
            <h5>Exterior Painting</h5>
            <p>
                Our experienced and professional painters know what it takes to deliver a
                high-quality finish that will protect and keep your home looking
                great for the years to come.
                <br></br>
                <br></br>
                We know that the use of the right tools, right preparation, and high-quality
                paints are the most important factors in achieving the best results.
                That's why we use <b>Sherwin-Williams</b> and <b>Benjamin Moore</b> paints
                for our exterior painting projects.
                <br></br>
                <br></br>
                Exterior Paint projects include:
            </p>
            <ul style={{marginLeft: "30px"}}>
                <li>Power washing</li>
                <li>Power Sanding</li>
                <li>Painting stucco, aluminum, and vinyl siding</li>
                <li>Painting brick and HardiePlank</li>
                <li>Painting wood siding and trim</li>
                <li>Epoxy coatings (Garage floors and basements)</li>
                <li>Carpentry repairs and wood rot replacement (We use AZEK for exterior
                    trim replacement)
                </li>
                <li>Deck staining</li>
            </ul>
        </div>      
    )
}

export default ServiceExteriorPaintingDescription;