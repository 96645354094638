import React from 'react'

function ServiceCarpentryDescription(props) {
    if(props.short) {
        return(
            <React.Fragment>
                <h5>Carpentry</h5>
                <p>
                    Our experienced, skilled, professional carpenters can take on a wide range
                    of woodworking projects for you...
                </p>
            </React.Fragment>
        )
    }

    return(
        <div>
            <h5>Carpentry</h5>
            <p>
                Our experienced, skilled, professional carpenters can take on a wide range
                of woodworking projects for you.
                <br></br>
                They will provide you with outstanding craftsmanship and caring customer 
                service.
                <br></br>
                <br></br>
                We are able to take on the following projects for you:
            </p>
            <ul style={{marginLeft: "30px"}}>
                <li>Trim installation (baseboards, casings, crown moldings, chair rails,
                    and other decorative moldings)
                </li>
                <li>Door and window installation</li>
                <li>Wainscoting</li>
                <li>Custom built-in units</li>
                <li>Fireplace mantel and shelf installation</li>
                <li>Ceiling installation (Drywall drop ceiling)</li>
                <li>Drywall repair and installation</li>
            </ul>
        </div>      
    )
}

export default ServiceCarpentryDescription;