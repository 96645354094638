import image1 from '../img/servicesGallery/decks/1.jpg'
import image2 from '../img/servicesGallery/decks/2.jpg'
import image3 from '../img/servicesGallery/decks/3.jpg'
import image4 from '../img/servicesGallery/decks/4.jpg'
import image5 from '../img/servicesGallery/decks/5.jpg'
import image6 from '../img/servicesGallery/decks/6.jpg'
import image7 from '../img/servicesGallery/decks/7.jpg'

export const DecksGallery =
        [
            {
              src: image1,
              width: 4,
              height: 5
            },
            {
              src: image2,
              width: 4,
              height: 5
            },
            {
              src: image3,
              width: 5,
              height: 3
            },
            {
              src: image4,
              width: 5,
              height: 3
            },
            {
              src: image5,
              width: 4,
              height: 5
            },
            {
              src: image6,
              width: 4,
              height: 6
            },
            {
              src: image7,
              width: 5,
              height: 3
            }
        ]